<template>
  <page-layout id="myOrder">
    <a-card>
      <div class="classify_box">
        <div class="flex1">
          <span>选择分类：</span>
          <span class="classify">
            <span
              :class="query.orderStatus == item.statusNum ? 'active' : ''"
              v-for="(item, index) in statusList"
              :key="index"
              @click="
                (query.orderStatus = item.statusNum),
                  clearPage(),
                  getList(),
                  getData()
              "
            >
              {{ item.name }}
            </span>
          </span>
        </div>
      </div>
      <div class="classify_box">
        <div class="flex1">
          <span>订单类型：</span>
          <span class="classify">
            <span
              :class="query.orderType == 0 ? 'active' : ''"
              @click="(query.orderType = 0), clearPage(), getList(), getData()"
            >
              全部
            </span>
            <span
              :class="query.orderType == 4 ? 'active' : ''"
              @click="(query.orderType = 4), clearPage(), getList(), getData()"
            >
              套餐订单
            </span>
            <span
              :class="query.orderType == 1 ? 'active' : ''"
              @click="(query.orderType = 1), clearPage(), getList(), getData()"
            >
              单品订单
            </span>
          </span>
        </div>
      </div>
      <div>
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-form-model layout="inline" :model="query" @submit.native.prevent>
              <a-form-model-item label="时间筛选">
                <a-select
                  style="width: 100px"
                  v-model="timeType"
                  placeholder="请选择"
                  allowClear
                >
                  <a-select-option :value="1">下单时间</a-select-option>
                  <a-select-option :value="2">支付时间</a-select-option>
                  <a-select-option :value="3">发货时间</a-select-option>
                </a-select>
                <a-range-picker
                  style="width: 250px; margin-left: 16px"
                  @change="changeDateTime"
                />
                <a-button
                  type="primary"
                  style="
                    margin-left: 16px;
                    vertical-align: middle;
                    margin-bottom: 4px;
                  "
                  @click="getQuery"
                >
                  查询
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
          <a-col>
            <div class="flex">
              <a-input-search
                placeholder="输入订单编号/商品名称搜索"
                v-model="query.search"
                enter-button="搜索"
                style="width: 300px; margin-right: 16px"
                @search="getList(), getData()"
              />
              <!-- <div class="header-search" style="margin-right: 16px;">
                  <a-input placeholder="输入订单编号/商品名称搜索" v-model="query.search" />
                  <span class="search" @click="getList(), getData()"><a-icon type="search" class="search-icon" /></span>
                </div> -->
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <a-card style="margin-top: 12px">
      <div style="margin-bottom: 16px">
        <a-button
          type="primary"
          :disabled="!canBatch"
          style="margin-right: 16px"
          @click="batchReceiving"
        >
          批量收货
        </a-button>
        <a-button @click="exportOrder(0)"> 导出订单 </a-button>
      </div>
      <a-table
        class="table"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        :scroll="{ x: 1300 }"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: (record) => ({
            props: {
              disabled:
                record.payType === 6 ||
                record.payType === 7 ||
                record.status != 3,
            },
          }),
          onChange: onSelectChange,
        }"
        :row-key="(record) => record.id"
      >
        <div slot="detail" slot-scope="text, record" class="detail">
          <div class="top">
            <span class="marginL8 orderId">订单号:{{ record.orderId }}</span>
            <span class="marginL8">{{ record.addTime }}</span>
            <span
              :class="
                record.combo == 2
                  ? 'set_meal marginL8'
                  : 'set_meal set_meal1 marginL8'
              "
            >
              {{ record.combo == 2 ? "套餐订单" : "单品订单" }}
            </span>
            <span
              v-if="record.isCust"
              class="set_meal marginL8"
              style="background-color: burlywood"
            >
              定制商品
            </span>
            <!-- <span class="marginL8 origin" v-if="record.invoice_type == 4">开票失败原因：{{ record.invoice_fail }}</span> -->
          </div>
          <div class="table_down">
            <img
              style="object-fit: contain"
              :src="record.productInfo[0].image"
              alt=""
            />
            <div class="goods_info">
              <div class="name">{{ record.productInfo[0].storeName }}</div>
              <div class="sku_box">
                <div>商品规格：{{ record.productInfo[0].specName }}</div>
                <div>
                  <span class="red"> ￥{{ record.productInfo[0].price }} </span>
                  *{{ record.productInfo[0].cart_num }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="more_product"
            v-if="record.productInfo.length > 1"
            @click="openOther(record)"
          >
            还有{{ record.productInfo.length - 1 }}
            件商品，点击查看
          </div>
        </div>
        <div slot="receiver" slot-scope="text, record" class="content">
          <div>{{ record.realName }}</div>
          <div>{{ record.userPhone }}</div>
        </div>
        <div slot="orderMoney" slot-scope="text, record" class="content">
          <div>￥{{ record.totalPrice | price }}</div>
          <div>运费:￥{{ record.totalPostage | price }}</div>
        </div>
        <div slot="money" slot-scope="text, record" class="content">
          <div v-if="record.status != 0">
            <div>￥{{ record.payPrice | price }}</div>
            <div>运费:￥{{ record.payPostage | price }}</div>
          </div>
          <div v-else>未支付</div>
        </div>
        <div slot="payType" slot-scope="text, record" class="content">
          <div>{{ record.payName }}</div>
        </div>
        <div slot="payTime" slot-scope="text, record" class="content">
          {{ record.payTime }}
        </div>
        <div slot="orderState" slot-scope="text, record" class="content">
          <div>{{ record.statusName }}</div>
          <a-statistic-countdown
            v-if="
              record.status == 0 &&
              record.certificate_status != 1 &&
              record.certificate_status != 3
            "
            :value="record.endOrderCancelTime * 1000"
            @finish="onFinish"
          />
        </div>
        <div slot="action" slot-scope="text, record" class="action">
          <span
            @click="
              $router.push({
                path: 'orderDetail',
                query: { id: record.orderId },
              })
            "
            style="cursor: pointer"
          >
            查看详情
          </span>
          <span
            v-if="
              record.status == 2 ||
              record.status == 3 ||
              record.status == 4 ||
              record.status == 6
            "
            class="mt16"
            style="cursor: pointer"
            @click="againBuy(record.orderId)"
          >
            再来一单
          </span>
        </div>
      </a-table>
      <div style="margin-top: 20px; text-align: right">
        <a-pagination
          v-if="!!total"
          v-model="current"
          :page-size-options="pageSizeOptions"
          :page-size="pageSize"
          :total="total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </a-card>

    <child-product
      ref="childProduct"
      :title="otherTitle"
      :data="otherProduct"
    />
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import childProduct from "@/components/childProduct";
import { orderDataStatistics, getListV3, buyAgain } from "@/api/myOrder";
import { batchTaskOrder } from "@/api/recordInvoice";
import { price } from "@/utils/filters";

export default {
  name: "myOrder",
  components: { PageLayout, childProduct },
  data() {
    // 订单状态 0待付款  1已付款/待审核  2部分已发货  3待收货  4已完成  5已取消 6待发货 7已退款
    return {
      statusList: [
        {
          name: "全部",
          statusNum: -1,
        },
        {
          name: "待付款",
          statusNum: 0,
        },
        {
          name: "待发货",
          statusNum: 1,
        },
        {
          name: "部分已发货",
          statusNum: 2,
        },
        {
          name: "待收货",
          statusNum: 3,
        },
        {
          name: "已完成",
          statusNum: 4,
        },
        {
          name: "已取消",
          statusNum: 5,
        },
      ],
      // data,
      data: [],
      columns,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      current: 1,
      pageSize: 10,
      total: 0,
      query: {
        page: 1,
        limit: 10,
        orderType: 0,
        orderStatus: -1,
        search: "",
      },
      obligation: 0,
      stayDeliver: 0,
      portionDeliver: 0,
      stayTake: 0,
      finish: 0,
      canceled: 0,
      orderId: "",
      payPrice: "",
      loading: false,
      otherProduct: [],
      otherTitle: "",
      selectedRowKeys: [],
      timeType: undefined,
      canBatch: true,
    };
  },
  filters: { price },
  mounted() {
    this.getList();
    this.getData();
  },
  methods: {
    batchReceiving() {
      if (this.selectedRowKeys.length == 0) {
        return this.$message.warning("请选择订单");
      }
      batchTaskOrder({
        orderSns: this.selectedRowKeys.join(","),
      }).then(() => {
        this.$message.success("收货成功");
        this.getList();
        this.getData();
        this.selectedRowKeys = [];
        this.query.page = 1;
      });
    },
    getQuery() {
      this.query.page = 1;
      this.query.timeType = this.timeType;
      this.getList();
    },
    changeDateTime(date, dateString) {
      this.query.timeDate =
        dateString.join("/") == "/" ? "" : dateString.join("/");
    },
    onSelectChange(selectedRowKeys, list) {
      this.selectedRowKeys = selectedRowKeys;
      this.canBatch = list.findIndex((item) => item.status != 3) == -1;
    },
    exportOrder(flag) {
      let form = Object.assign({}, this.query);
      if (flag == 1 && this.selectedRowKeys.length == 0) {
        this.$message.warning("请选择要导出的订单");
        return false;
      }
      if (flag == 1) {
        form = Object.assign({}, this.query, {
          order_ids: this.selectedRowKeys.join(","),
        });
      }
      form.isExport = 1;
      delete form.page;
      delete form.limit;
      getListV3(form).then((res) => {
        const { export_json_to_excel } = require("@/Excel/Export2Excel.js");
        const tHeader = [
          "行号",
          "订单编号",
          "商品类别",
          "商品名称",
          "商品编号",
          "单位",
          "数量",
          "商品单价",
          "商品金额",
          "订单金额(订单)",
          "订单运费",
          "退货数量",
          "退货金额",
          "交易状态",
          "发货时间",
          "付款时间",
          "下单时间",
          "买家姓名",
          "收货人联系方式",
          "详细地址",
          "省",
          "市",
          "区",
          "物流公司",
          "物流单号",
          "发票号码",
          "开票时间",
        ]; // 上面设置Excel的表格第一行的标题
        const filterVal = [
          "index",
          "order_id",
          "type",
          "store_name",
          "bar_code",
          "unit",
          "cart_num",
          "price",
          "good_price",
          "order_price",
          "pay_postage",
          "return_num",
          "return_price",
          "order_status",
          "deliver_time",
          "pay_time",
          "add_time",
          "real_name",
          "user_phone",
          "address",
          "provincial",
          "civic",
          "region",
          "freight_name",
          "freight_billno",
          "invoice_number",
          "invoice_time",
        ]; // 上面的index、nickName、name是tableData里对象的属性
        const list = res.data.data.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "订单列表");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, index) =>
        filterVal.map((j) => {
          let item;
          switch (j) {
            case "index":
              item = index + 1;
              break;
            default:
              item = v[j];
              break;
          }
          return item;
        })
      );
    },
    againBuy(orderId) {
      const hide = this.$message.loading("订单生成中,请稍等...", 0);
      buyAgain({ orderSn: orderId })
        .then((res) => {
          hide();
          if (res.data.data.cart_id) {
            this.$router.push({
              path: "/bookBuy/submitOrder",
              query: {
                cartId: res.data.data.cart_id,
                originOrderId: orderId,
                new: 1,
              },
            });
          } else {
            this.$message.error("该订单包含商品已下架或库存不足");
          }
        })
        .catch(() => {
          hide();
        });
    },
    openOther(record) {
      console.log(record);
      this.otherProduct = record.productInfo;
      this.otherTitle = "订单号：" + record.orderId;
      this.$refs.childProduct.visible = true;
    },
    clearPage() {
      this.query.page = 1;
    },
    onFinish() {
      this.getList();
      this.getData();
    },
    getList() {
      this.loading = true;
      getListV3(this.query)
        .then((res) => {
          this.data = res.data.data.list;
          this.total = res.data.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDate(time, newTime) {
      let sTime = parseInt((time - newTime) / 1000);
      let hours = this.addZero(Math.floor(sTime / 60 / 60));
      let min = this.addZero(Math.floor(sTime / 60));
      let sed = this.addZero(Math.floor(sTime % 60));
      return hours + ":" + min + ":" + sed;
    },
    addZero(val) {
      return val < 10 ? "0" + val : val;
    },
    getData() {
      this.selectedRowKeys = [];
      orderDataStatistics().then((res) => {
        this.obligation = res.data.data.obligation;
        this.stayDeliver = res.data.data.stayDeliver;
        this.portionDeliver = res.data.data.portionDeliver;
        this.stayTake = res.data.data.stayTake;
        this.finish = res.data.data.finish;
        this.canceled = res.data.data.canceled;
      });
    },
    onChange(page, pageSize) {
      this.pageSize = pageSize;
      this.current = page;
      this.query.page = page;
      this.query.limit = pageSize;
      this.getList();
      this.getData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.query.page = current;
      this.query.limit = pageSize;
      this.getList();
      this.getData();
    },
  },
};
const columns = [
  {
    title: "订单详情",
    dataIndex: "detail",
    key: "detail",
    width: 360,
    scopedSlots: { customRender: "detail" },
  },
  {
    title: "收货人",
    dataIndex: "receiver",
    key: "receiver",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "receiver" },
  },
  {
    title: "订单金额",
    dataIndex: "orderMoney",
    key: "orderMoney",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "orderMoney" },
  },
  {
    title: "实际支付",
    dataIndex: "money",
    key: "money",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "money" },
  },
  {
    title: "支付方式",
    dataIndex: "payType",
    key: "payType",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "payType" },
  },
  {
    title: "支付时间",
    dataIndex: "payTime",
    key: "payTime",
    align: "center",
    scopedSlots: { customRender: "payTime" },
    width: 150,
  },
  {
    title: "订单状态",
    dataIndex: "orderState",
    key: "orderState",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "orderState" },
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    align: "center",
    width: 120,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
</script>

<style lang="less" scoped>
@import "myOrder";
</style>
